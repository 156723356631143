import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Policy from './Policy';
import Terms from './Terms';
import Admin from './admin/Admin';
import Login from './admin/pages/Login';
import Adatok from './Adatok';
import HomePage from './HomePage';

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route exact path="/terms">
                    <Terms />
                </Route>
                <Route path="/policy">
                    <Policy />
                </Route>
                <Route path="/admin">
                    <Admin />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/adatok">
                    <Adatok />
                </Route>
                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </Router>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));
