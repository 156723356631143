import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    Snackbar,
    TextField,
    Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { addBrand, getBrands } from '../actions/brands';

function CategoriesPage() {
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnakbarOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [brands, setBrands] = useState([]);

    const fetchBrands = () => {
        setLoading(true);
        getBrands()
            .then((allBrands) => {
                setBrands(allBrands);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchBrands();
    }, []);

    const filteredBrands = useMemo(() => brands.filter((e) => e.includes(search.toUpperCase())), [
        search,
        brands,
    ]);
    const hasSearch = search.length > 3;

    const onAdd = useCallback(() => {
        if (!search.trim()) {
            return;
        }
        setLoading(true);
        addBrand(search.trim().toUpperCase()).then(() => {
            setBrands((prev) => [...prev, search.trim().toUpperCase()]);
            setLoading(false);
        });
    }, [search]);

    if (loading) {
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <CircularProgress color="secondary" />
                </Box>
            </div>
        );
    }

    return (
        <Box>
            <Typography style={{ marginBottom: 8 }} variant="h5">
                Márkák
            </Typography>
            <Typography>
                Keresés és hozzáadás. Az újonnan hozzáadott márkák legkésőbb 24 óra múlva jelennek
                meg az alkalmazásban. FONTOS: márka törlését csak fejlesztő tudja elvégezni.
            </Typography>
            <TextField
                label="Keresés"
                fullWidth
                value={search}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => setSearch('')}>
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => setSearch(e.target.value)}
            />
            <List>
                {hasSearch &&
                    filteredBrands.length > 0 &&
                    filteredBrands.map((e) => <ListItem key={e}>{e}</ListItem>)}
                {!hasSearch && <Typography>Használja a keresőt.</Typography>}
                {hasSearch && filteredBrands.length === 0 && (
                    <Box>
                        <Typography>
                            Nincs találat. Adja hozzá az alábbi gomb használatával.
                        </Typography>
                        <Button variant="contained" onClick={onAdd}>
                            Hozzáadás
                        </Button>
                    </Box>
                )}
            </List>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnakbarOpen(false)}
                message="Hiba! A kategóriához tartozik legalább egy hirdetés."
            />
        </Box>
    );
}

export default CategoriesPage;
