import React, { useMemo } from 'react';
import {
    AppBar,
    Container,
    IconButton,
    ThemeProvider,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { StyledFirebaseAuth } from 'react-firebaseui';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import firebase from '../firebase';
import { useStyles } from '../useStyles';
import { theme } from '../theme';

export default function Login() {
    const history = useHistory();
    const classes = useStyles();

    const config = useMemo(
        () => ({
            callbacks: {
                signInSuccessWithAuthResult() {
                    history.replace('/admin');
                    return false;
                },
                signInFailure(error) {
                    console.log('error', error);
                },
            },
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: false,
                },
            ],
        }),
        [history],
    );
    return (
        <ThemeProvider theme={theme}>
            <AppBar position="absolute" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        className={clsx(classes.menuButton)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        SznobRaktár Admin
                    </Typography>
                </Toolbar>
            </AppBar>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container style={{ marginTop: 12 }}>
                    <StyledFirebaseAuth uiConfig={config} firebaseAuth={firebase.auth()} />
                </Container>
            </main>
        </ThemeProvider>
    );
}
