import React, { useEffect, useState } from 'react';
import { Container, CssBaseline, Drawer, IconButton, ThemeProvider } from '@material-ui/core';
import clsx from 'clsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { theme } from './theme';
import { useStyles } from './useStyles';
import SideMenu from './components/SideMenu';
import firebase from './firebase';
import CategoriesPage from './pages/CategoriesPage';
import Header from './components/Header';
import PendingArticlesPage from './pages/PendingArticlesPage';
import ArticleDetailPage from './pages/ArticleDetailPage';
import BrandsPage from './pages/BrandsPage';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export default function Admin() {
    const classes = useStyles();
    const [open, setOpen] = useState(!isMobile);
    const handleDrawerOpen = () => {
        if (isMobile) {
            return;
        }
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    const { path } = useRouteMatch();

    const history = useHistory();
    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
            if (!user) {
                history.replace('/login');
                return;
            }

            try {
                const token = await user.getIdTokenResult();
                if (!token.claims.admin) {
                    history.replace('/login');
                }
            } catch (e) {
                console.log(e);
                history.replace('/login');
            }
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ display: 'flex' }}>
                <Header classes={classes} open={open} onClick={handleDrawerOpen} />
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <SideMenu />
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="md" className={classes.container}>
                        <Switch>
                            <Route exact path={path}>
                                <PendingArticlesPage />
                            </Route>
                            <Route exact path={`${path}/articles/:id`}>
                                <ArticleDetailPage />
                            </Route>
                            <Route path={`${path}/categories`}>
                                <CategoriesPage />
                            </Route>
                            <Route path={`${path}/brands`}>
                                <BrandsPage />
                            </Route>
                            <Route path={`${path}/articles`}>
                                <PendingArticlesPage />
                            </Route>
                        </Switch>
                    </Container>
                </main>
            </div>
        </ThemeProvider>
    );
}
