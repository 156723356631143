import firebase from '../firebase';
import { toArray } from '../../util';

const firestore = firebase.firestore();

// eslint-disable-next-line import/prefer-default-export
export async function getPendingArticles() {
    try {
        const snapshot = await firestore
            .collection('articles')
            .where('status', '==', 'pending')
            .get();
        return toArray(snapshot);
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function getArticle(id) {
    try {
        const snapshot = await firestore.collection('articles').doc(id).get();
        return toArray([snapshot])[0];
    } catch (e) {
        console.log(e);
        throw new Error('could not fetch article');
    }
}

export async function acceptArticle(id) {
    await firestore.collection('articles').doc(id).update({ status: 'approved' });
}

export async function deleteArticle(id) {
    await firestore.collection('articles').doc(id).delete();
}

export async function requestChange(id, message) {
    if (!message.trim()) {
        throw new Error('Kérjük töltse ki az üzenetet');
    }
    if (message.trim().length > 250) {
        throw new Error('Túl hosszű üzenet');
    }

    const requestArticleChange = firebase.functions().httpsCallable('requestArticleChange');

    try {
        await requestArticleChange({ articleId: id, message: message.trim() });
    } catch (e) {
        console.log(e);
    }
}

export async function getReports(id) {
    try {
        const reports = await firestore
            .collection('article_reports')
            .where('articleId', '==', id)
            .orderBy('createdAt', 'desc')
            .get();
        return toArray(reports);
    } catch (e) {
        console.log(e);
        return [];
    }
}
