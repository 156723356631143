import React, { useCallback, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Snackbar,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Icon from '@mdi/react';
import * as allIcons from '@mdi/js';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteSubCategory, getAllCategories } from '../actions/categories';
import { useStyles } from '../useStyles';
import CategoryForm from '../components/CategoryForm';
import { getIconObject } from '../../util';

function DeleteDialog({ article, open, onClose, onDelete }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {article && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        Biztosan törli a(z) &apos;{article.title}&apos; kategóriát?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            A kategória törlését csak akkor engedi a rendszer, ha jelenleg nem
                            tartozik hozzá hirdetés.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Vissza</Button>
                        <Button onClick={onDelete} color="primary" autoFocus>
                            Törlöm
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

function CategoriesPage() {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [deleteCategory, setDeleteCategory] = useState(null);
    const [snackbarOpen, setSnakbarOpen] = useState(false);

    const fetchCategories = () => {
        setLoading(true);
        getAllCategories()
            .then((c) => {
                setCategories(c);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const onDeleteCategory = useCallback(() => {
        if (!deleteCategory) {
            return;
        }

        deleteSubCategory(deleteCategory)
            .then(() => {
                setDeleteCategory(null);
                fetchCategories();
            })
            .catch(() => {
                setSnakbarOpen(true);
                setDeleteCategory(null);
            });
    }, [deleteCategory]);

    if (loading) {
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <CircularProgress color="secondary" />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Typography style={{ marginBottom: 8 }} variant="h5">
                Kategóriák
            </Typography>
            {categories.map((category) => (
                <Accordion key={category.id}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Icon
                            path={allIcons[getIconObject(category.icon)]}
                            size={1}
                            style={{ marginRight: 12 }}
                        />
                        <Typography className={classes.heading}>{category.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: 'column', alignItems: 'start' }}>
                        {category.subCategories.length === 0 ? (
                            <Typography>Nincs alkategória</Typography>
                        ) : (
                            <List style={{ width: '100%' }}>
                                {category.subCategories.map((subCat) => (
                                    <ListItem key={subCat.id} button>
                                        <ListItemText primary={subCat.title} />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => setDeleteCategory(subCat)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        <CategoryForm parentId={category.id} onSuccess={fetchCategories} />
                    </AccordionDetails>
                </Accordion>
            ))}
            <Box marginTop={2}>
                <CategoryForm onSuccess={fetchCategories} />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnakbarOpen(false)}
                message="Hiba! A kategóriához tartozik legalább egy hirdetés."
            />
            <DeleteDialog
                open={deleteCategory !== null}
                article={deleteCategory}
                onClose={() => setDeleteCategory(null)}
                onDelete={onDeleteCategory}
            />
        </div>
    );
}

export default CategoriesPage;
