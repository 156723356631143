import React, { useCallback, useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@material-ui/core';
import { createCategory } from '../actions/categories';

export default function CategoryForm({ parentId = null, onSuccess = () => {} }) {
    const [open, setOpen] = useState(false);

    const [title, setTitle] = useState('');
    const [icon, setIcon] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setError(false);
            setLoading(true);
            createCategory({ title, parent_id: parentId, icon })
                .then(() => {
                    setLoading(false);
                    setOpen(false);
                    onSuccess();
                })
                .catch((err) => {
                    console.log(err);
                    setError(true);
                    setLoading(false);
                });
        },
        [title, parentId, icon],
    );

    return (
        <>
            {!open ? (
                <Button color="secondary" variant="outlined" onClick={() => setOpen(true)}>
                    + Új {parentId !== null ? 'al' : ''}
                    kategória
                </Button>
            ) : (
                <Box width="100%">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <form onSubmit={onSubmit}>
                            {parentId === null && (
                                <Box marginBottom={1} display="flex" alignItems="center">
                                    <TextField
                                        label="Ikon angol neve"
                                        fullWidth
                                        error={error}
                                        value={icon}
                                        onChange={(e) => setIcon(e.target.value)}
                                    />
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => {
                                            window.open(
                                                'https://materialdesignicons.com/',
                                                '_blank',
                                            );
                                        }}
                                    >
                                        Keresés
                                    </Button>
                                </Box>
                            )}
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label={`${parentId !== null ? 'Al' : 'Fő'}kategória neve`}
                                    fullWidth
                                    error={error}
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <Button type="submit" color="secondary" variant="contained">
                                    Mentés
                                </Button>
                            </Box>
                        </form>
                    )}
                </Box>
            )}
        </>
    );
}
