import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    CircularProgress,
    Grid,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/hu';

import { useHistory } from 'react-router-dom';
import { getPendingArticles } from '../actions/articles';
import { formatNumber } from '../../util';

moment.locale('HU');

function PendingArticlesPage() {
    const [loading, setLoading] = useState(true);
    const [articles, setArticles] = useState([]);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getPendingArticles().then((a) => {
            setArticles(a);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <CircularProgress color="secondary" />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h5" style={{ marginBottom: 8 }}>
                Új és módosított hirdetések
            </Typography>
            <Grid container spacing={3}>
                {articles.length === 0 && (
                    <Grid item>
                        <Typography>Jelenleg nincs függőben lévő hirdetés.</Typography>
                    </Grid>
                )}
                {articles.map((article) => (
                    <Grid
                        key={article.id}
                        item
                        xs={12}
                        md={4}
                        onClick={() => history.push(`/admin/articles/${article.id}`)}
                    >
                        <Card elevation={4} style={{ cursor: 'pointer' }}>
                            <CardHeader title={article.title} subheader={article.userName} />
                            <CardMedia
                                image={article.images[0]}
                                title="Paella dish"
                                style={{ height: 250 }}
                            />
                            <CardContent>
                                <Typography component="h3">
                                    {formatNumber(article.price)} HUF
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    {moment(article.createdAt.toDate()).fromNow()}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default PendingArticlesPage;
