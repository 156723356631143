import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: 'AIzaSyCnS_KJS1wZbfeb_Ewols02qfs7jMxHp-c',
    authDomain: 'sznobraktar-dc4ec.firebaseapp.com',
    databaseURL: 'https://sznobraktar-dc4ec.firebaseio.com',
    projectId: 'sznobraktar-dc4ec',
    storageBucket: 'sznobraktar-dc4ec.appspot.com',
    messagingSenderId: '151296743651',
    appId: '1:151296743651:web:1f43aa287bdb0613a50dd6',
    measurementId: 'G-94EL0EEKXN',
};

firebase.initializeApp(firebaseConfig);

export default firebase;
