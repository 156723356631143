import firebase from '../firebase';
import { toArray } from '../../util';

const firestore = firebase.firestore();

export async function getBrands() {
    try {
        let all = await firestore.collection('brands').get();
        all = [...new Set(toArray(all).map((e) => e.brand.toUpperCase().trim()))];

        return all;
    } catch (e) {
        console.log(e);
        return [];
    }
}

export async function addBrand(brand) {
    try {
        await firestore.collection('brands').add({
            brand: brand.trim().toUpperCase(),
        });
    } catch (e) {
        console.log(e);
    }
}
