import React, { useCallback, useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import { useHistory, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import moment from 'moment';
import {
    acceptArticle,
    deleteArticle,
    getArticle,
    getReports,
    requestChange,
} from '../actions/articles';
import { getAllCategories, getCategoryById, getMetadata } from '../actions/categories';
import 'moment/locale/hu';
import { formatNumber } from '../../util';

moment.locale('HU');

const messages = {
    pictures: 'Kérjük töltsön fel jobb minőségű képeket.',
    description: 'Kérjük adjon meg egy részletesebb leírást.',
    price: 'Kérjük módosítsa a hirdetés árát.',
    other: '',
};

function DeleteDialog({ article, open, onClose, onDelete }) {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {article && (
                <>
                    <DialogTitle id="alert-dialog-title">
                        Biztosan törli a(z) &apos;{article.title}&apos; hirdetést?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Törlés után a hirdetés nem visszaállítható.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Vissza</Button>
                        <Button onClick={onDelete} color="primary" autoFocus>
                            Törlöm
                        </Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

function ChangeDialog({ open = true, userName, onClose, onRequestChange = () => {} }) {
    const [isReview, setIsReview] = useState(false);
    const [value, setValue] = useState('pictures');
    const [message, setMessage] = useState(messages.pictures);
    const [error, setError] = useState(false);

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    useEffect(() => {
        setMessage(messages[value]);
    }, [value]);

    const onClickSend = useCallback(() => {
        setError(false);
        if (!isReview) {
            if (!message.trim() || message.trim().length > 250) {
                setError(true);
                return;
            }
            setIsReview(true);
            return;
        }

        onRequestChange(message);
    }, [isReview, message]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Üzenet - {userName}</DialogTitle>
            <DialogContent>
                {isReview ? (
                    <>
                        <DialogContentText id="alert-dialog-description">
                            Üzenet átnézése
                        </DialogContentText>
                        <Typography>{message}</Typography>
                    </>
                ) : (
                    <>
                        <DialogContentText id="alert-dialog-description">
                            Válasszon üzenetet vagy írja meg sajátját.
                        </DialogContentText>
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                            <FormLabel component="legend">Üzenet</FormLabel>
                            <RadioGroup
                                aria-label="message"
                                name="message1"
                                value={value}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="pictures"
                                    control={<Radio />}
                                    label="Szebb képek"
                                />
                                <FormControlLabel
                                    value="description"
                                    control={<Radio />}
                                    label="Részletesebb leírás"
                                />
                                <FormControlLabel
                                    value="price"
                                    control={<Radio />}
                                    label="Ár módosítása"
                                />
                                <FormControlLabel
                                    value="other"
                                    control={<Radio />}
                                    label="Saját üzenet"
                                />
                            </RadioGroup>
                            <TextField
                                error={error}
                                autoFocus
                                fullWidth
                                helperText="Saját üzenet"
                                multiline
                                placeholder="Kérjük módosítsa..."
                                rows={4}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </FormControl>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={isReview ? () => setIsReview(false) : onClose}>Vissza</Button>
                <Button onClick={onClickSend} color="primary" autoFocus>
                    Küldés
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function ArticleDetailPage() {
    const { id } = useParams();

    const history = useHistory();

    const [article, setArticle] = useState(null);
    const [loading, setLoading] = useState(true);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [changeDialogOpen, setChangeDialogOpen] = useState(false);
    const [reports, setReports] = useState([]);

    useEffect(() => {
        async function fetch() {
            setLoading(true);
            try {
                const c = await getAllCategories();
                const metedata = await getMetadata();
                const a = await getArticle(id);
                const cat = getCategoryById(c, a.category);
                a.categoryTitle = cat?.title || 'Ismeretlen';
                a.typeName = cat?.types?.find((t) => t.id === a.type)?.name || 'Ismeretlen';
                a.metadata = Object.keys(a.metadata_values || {})
                    .map((key) => {
                        const meta = metedata.find((m) => m.id === key);
                        if (!meta) {
                            return null;
                        }
                        return {
                            key: meta.name,
                            value: a.metadata_values[key],
                        };
                    })
                    .filter((m) => m !== null);
                setArticle(a);
                const r = await getReports(id);
                setReports(r);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }

        fetch();
    }, [id]);

    const onAccept = useCallback(() => {
        setLoading(true);
        acceptArticle(id)
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, [id]);

    const onDelete = useCallback(() => {
        setDeleteDialogOpen(false);
        setLoading(true);
        deleteArticle(id)
            .then(() => {
                setLoading(false);
                history.goBack();
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
            });
    }, [id]);

    const onRequestChange = useCallback(
        (message) => {
            setDeleteDialogOpen(false);
            setLoading(true);
            requestChange(id, message)
                .then(() => {
                    setLoading(false);
                    history.goBack();
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
        },
        [id],
    );

    if (loading) {
        return (
            <div>
                <Box display="flex" justifyContent="center">
                    <CircularProgress color="secondary" />
                </Box>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 8 }}>
                {article.title}
            </Typography>
            <Box marginBottom={2}>
                <ImageGallery
                    showPlayButton={false}
                    showBullets={false}
                    showNav={false}
                    infinite={false}
                    items={article.images.map((i) => ({ original: i, thumbnail: i }))}
                    renderItem={(item) => (
                        <img
                            src={item.original}
                            alt="Kép"
                            style={{ maxHeight: 500, maxWidth: '100%' }}
                        />
                    )}
                />
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Kategória</Typography>
                <Typography>{article.categoryTitle}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Típus</Typography>
                <Typography>{article.typeName}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Márka</Typography>
                <Typography>{article.brand}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Ár</Typography>
                <Typography>{formatNumber(article.price)} HUF</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Felahsználó</Typography>
                <Typography>{article.userName}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Hely</Typography>
                <Typography>{article.location}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Dátum</Typography>
                <Typography>{moment(article.createdAt.toDate()).calendar()}</Typography>
            </Box>
            <Box marginBottom={2}>
                <Typography variant="h6">Leírás</Typography>
                <Typography>{article.description}</Typography>
            </Box>
            {article.metadata.map((meta) => (
                <Box key={meta.key} marginBottom={2}>
                    <Typography variant="h6">{meta.key}</Typography>
                    <Typography>{meta.value}</Typography>
                </Box>
            ))}
            {reports.length > 0 && (
                <Box marginBottom={2}>
                    <Typography variant="h6">Jelentések</Typography>
                    <Grid container spacing={3}>
                        {reports.map((report) => (
                            <Grid key={report.id} item xs={12} md={4}>
                                <Card elevation={4}>
                                    <CardHeader
                                        title={report.userName}
                                        subheader={moment(report.createdAt.toDate()).fromNow()}
                                    />
                                    <CardContent>
                                        <Typography component="p">{report.message}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}
            <Grid container direction="row" alignItems="center" spacing={3}>
                <Grid item sm={4} xs={12} style={{ display: 'flex' }} justify="center">
                    <Button
                        variant="outlined"
                        style={{ color: 'green', borderColor: 'green' }}
                        onClick={onAccept}
                    >
                        Elfogadom
                    </Button>
                </Grid>
                <Grid item sm={4} xs={12} style={{ display: 'flex' }} justify="center">
                    <Button
                        variant="outlined"
                        style={{ color: '#ff9966', borderColor: '#ff9966' }}
                        onClick={() => setChangeDialogOpen(true)}
                    >
                        Módosítást kérek
                    </Button>
                </Grid>
                <Grid item sm={4} xs={12} style={{ display: 'flex' }} justify="center">
                    <Button
                        variant="outlined"
                        style={{ color: 'red', borderColor: 'red' }}
                        onClick={() => setDeleteDialogOpen(true)}
                    >
                        Törlöm
                    </Button>
                </Grid>
            </Grid>
            <DeleteDialog
                open={deleteDialogOpen}
                article={article}
                onDelete={onDelete}
                onClose={() => setDeleteDialogOpen(false)}
            />
            <ChangeDialog
                open={changeDialogOpen}
                onClose={() => setChangeDialogOpen(false)}
                userName={article.userName}
                onRequestChange={onRequestChange}
            />
        </div>
    );
}

export default ArticleDetailPage;
