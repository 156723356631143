import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import firebase from './admin/firebase';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Adatok() {
    const params = useQuery();
    const id = params.get('q');
    const [job, setJob] = useState(null);

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('delete_jobs')
            .doc(id)
            .onSnapshot((doc) => {
                setJob(doc.data());
            });

        return () => {
            unsubscribe();
        };
    }, [id]);

    if (!job) {
        return null;
    }

    return (
        <>
            <style>
                {"body{font-family:'Helvetica Neue',Helvetica,Arial,sans-serif;padding:1em}"}
            </style>
            <div>
                <h1>{job.displayName} adatainak törlése</h1>
                <progress
                    max="100"
                    value={`${job.progress}`}
                    style={{ width: 800, maxWidth: '100%' }}
                />
            </div>
        </>
    );
}
