import React from 'react';
import {
    Container,
    createMuiTheme,
    CssBaseline,
    Grid,
    Hidden,
    ThemeProvider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        width: 400,
        maxWidth: '90%',
        marginTop: '2em',
        [theme.breakpoints.up('md')]: {
            marginTop: '8em',
        },
    },
    link: {
        width: '70%',
        maxWidth: 400,
        position: 'relative',
    },
    storeImage: {
        width: '100%',
    },
    linkContainer: {
        display: 'flex',
    },
    linkGrid: {
        position: 'fixed',
        bottom: 0,
        left: 0,
    },
    background: {
        position: 'fixed',
        objectFit: 'contain',
        top: 0,
        // bottom: 0,
        zIndex: 0,
        [theme.breakpoints.up('md')]: {
            height: '100vh',
        },
        [theme.breakpoints.down('sm')]: {
            right: 0,
            width: '100vw',
        },
    },
    leftBackground: {
        left: 0,
    },
    rightBackground: {
        right: 0,
    },
}));

const theme = createMuiTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    background: `linear-gradient(135deg, #7d7d7d, #7d7d7d, #00000000 45%),                                
                                linear-gradient(180deg, #7b7b7b, #7b7b7b, #747474)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                },
                h1: {
                    color: '#fff',
                    textTransform: 'uppercase',
                    margin: 20,
                },
            },
        },
        MuiGrid: {
            container: {
                position: 'relative',
                fontFamily: 'Montserrat',
            },
        },
    },
});

function HomePage() {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Container className={classes.root}>
                <img
                    className={`${classes.background} ${classes.leftBackground}`}
                    src="/side_L.png"
                    alt="side_l"
                />
                <Hidden smDown>
                    <img
                        className={`${classes.background} ${classes.rightBackground}`}
                        src="/side_R.png"
                        alt="side_r"
                    />
                </Hidden>
                <Grid justify="center" container>
                    <Grid item align="center" xs={12} style={{ padding: 40 }}>
                        <img
                            className={classes.logo}
                            src="/logo_white_transparent.png"
                            alt="logo"
                            // style={{ height: '100px', zIndex: 1 }}
                        />
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.linkGrid}>
                    <Grid item xs={12} align="center">
                        <h1>Hamarosan Elérhető!</h1>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        alignItems="center"
                        justify="center"
                        align="center"
                        style={{ padding: 8 }}
                        className={classes.linkContainer}
                    >
                        <a href="/#" className={classes.link}>
                            <img
                                className={classes.storeImage}
                                alt="apple-store"
                                src="/Download_on_the_App_Store_Badge_HU_RGB_blk_100317.svg"
                            />
                        </a>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        alignItems="center"
                        justify="center"
                        align="center"
                        className={classes.linkContainer}
                    >
                        <a href="/#" className={classes.link}>
                            <img
                                className={classes.storeImage}
                                alt="play-store"
                                src="/google-play-badge.png"
                            />
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}

export default HomePage;
