import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import React, { useCallback } from 'react';
import CategoryIcon from '@material-ui/icons/Category';
import LockIcon from '@material-ui/icons/Lock';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AppleIcon from '@material-ui/icons/Apple';
import firebase from '../firebase';

export default function SideMenu() {
    const history = useHistory();
    const { path } = useRouteMatch();
    const onItemClick = useCallback(
        (pathToPush) => () => {
            history.push(`${path}/${pathToPush}`);
        },
        [history, path],
    );
    const isSelected = useCallback(
        (pathIsSelected = '') => {
            return history.location.pathname === `${path}/${pathIsSelected}`;
        },
        [history, path],
    );

    return (
        <List>
            <div>
                <ListItem
                    button
                    onClick={onItemClick('articles')}
                    selected={isSelected('articles')}
                >
                    <ListItemIcon>
                        <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary="Hirdetések" />
                </ListItem>
                <ListItem
                    button
                    onClick={onItemClick('categories')}
                    selected={isSelected('categories')}
                >
                    <ListItemIcon>
                        <CategoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kategóriák" />
                </ListItem>
                <ListItem button onClick={onItemClick('brands')} selected={isSelected('brands')}>
                    <ListItemIcon>
                        <AppleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Márkák" />
                </ListItem>
                <ListItem button onClick={() => firebase.auth().signOut()}>
                    <ListItemIcon>
                        <LockIcon />
                    </ListItemIcon>
                    <ListItemText primary="Kijelentkezés" />
                </ListItem>
            </div>
        </List>
    );
}
