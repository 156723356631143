export function toArray(data) {
    const ret = [];
    data.forEach((d) => {
        ret.push({ id: d.id, ...d.data() });
    });
    return ret;
}

export function formatNumber(n, handleFloat = false) {
    const maybeNum = parseInt(n, 10);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(maybeNum)) {
        return n;
    }

    const formatted = maybeNum.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1.');
    const split = `${n}`.split('.');
    const isFloat = split.length > 1;
    if (!isFloat || !handleFloat) {
        return formatted;
    }

    return `${formatted}.${split[1]}`;
}

export function getIconObject(icon) {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);
    const capitalized = icon
        .split('-')
        .map((s) => capitalize(s))
        .join('');
    return `mdi${capitalized}`;
}
